/**
 * @author emil.ohman@svenskaspel.se (Emil Öhman)
 */
'use strict';

import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './../../stylesheets/drawer.less';

/**
 * Display content that opens from the left or right side of the screen.
 */
const Drawer = (props) => {
  const drawerClassNames = [
    'drawer',
    'js-drawer',
    `drawer-position-${props.position}`,
    `js-drawer-position-${props.position}`
  ];

  const dimmerClassNames = [
    'drawer-dimmer',
    'js-drawer-dimmer',
    `drawer-dimmer-position-${props.position}`,
    `js-drawer-dimmer-position-${props.position}`
  ];

  if (props.autoFold) {
    drawerClassNames.push(`drawer-position-${props.position}-auto-fold`);
    dimmerClassNames.push(`drawer-dimmer-position-${props.position}-auto-fold`);
  }

  if (props.isSheet) {
    dimmerClassNames.push('drawer-dimmer-sheet');
    drawerClassNames.push('js-drawer-sheet drawer-sheet');
  }

  if (props.isOpen) {
    drawerClassNames.push(`drawer-position-${props.position}-open`);
    drawerClassNames.push(`drawer-position-${props.position}-animate-open`);
    if (!props.isDimmerDisabled && props.showDimmer) {
      dimmerClassNames.push(`drawer-dimmer-position-${props.position}-open`);
    }
  }

  if (props.disableDimmerClick) {
    dimmerClassNames.push('drawer-dimmer-disable-click');
  }

  if (props.className) {
    drawerClassNames.push(props.className);
  }
  const handleDimmerClick = (event) => {
    if (!props.disableDimmerClick) {
      props.onDimmerClicked(event);
    }
  };

  return ReactDOM.createPortal(
    <Fragment>
      <div className={dimmerClassNames.join(' ')} onClick={handleDimmerClick}/>
      <div className={`${drawerClassNames.join(' ')}`}>{props.children}</div>
    </Fragment>,
    document.body
  );
};

Drawer.defaultProps = {
  disableDimmerClick: false,
  isDimmerDisabled: false,
  isOpen: false,
  isSheet: false,
  position: 'left',
  showDimmer: true
};

Drawer.propTypes = {
  autoFold: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disableDimmerClick: PropTypes.bool,
  isDimmerDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSheet: PropTypes.bool,
  onDimmerClicked: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right', 'bottom']),
  showDimmer: PropTypes.bool
};

export default Drawer;
